import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"

import { partitionCases } from "../utils/partition-cases"
import { InterfaceContext } from "../context/interface"
import FeaturedCases from "../components/featured-cases"

const FeaturedCasesTemplate = ({ data, location, pageContext }) => {
  const { setTranslationLinks } = useContext(InterfaceContext)
  useEffect(() => {
    setTranslationLinks(pageContext.canonicals)
  }, [])

  const partitioned = partitionCases(data.featured.cases)
  const keys = Object.entries(partitioned).map(([next]) => next)

  return (
    <FeaturedCases
      nav={data.navigation}
      dropup={data.dropup}
      subNav={data.subnav}
      footerNav={data.footerNavigation} 
      caseKey="Video"
      caseCategories={keys}
      cases={partitioned["Video"]}
      data={data}
      location={location}
      pageContext={pageContext}
    />
  )
}

export default FeaturedCasesTemplate

export const featuredQuery = graphql`
  query VideoCase($slug: String!, $featured_slug: String!, $locale: String!) {
    subnav: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Subnavigation" }
    ) {
      ...navigationFields
    }
    dropup: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Services" }
    ) {
      ...navigationFields
    }
    navigation: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Main" }
    ) {
      ...navigationFields
    }
    footerNavigation: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Footer" }
    ) {
      ...footerNavigationFields
    }
    featured: contentfulFeaturedCases(slug: { eq: $featured_slug }) {
      id
      name
      slug
      cases {
        ...casePreviewFields
      }
    }
    currentCase: contentfulCase(slug: { eq: $slug }) {
      ...casePreviewFields
    }
  }
`
